<template>
  <div>
    <div class="container">
      <div class="dialogue-box has-header d-flex justify-content-center">
        <div class="dialogue-box-header">Listings for {{ categoryItemName }}</div>
        <div class="dialogue-box-inner p-4 px-5 w-100">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th></th>
                <th>Item Name</th>
                <th>Amount Available</th>
                <th>Price Per Item</th>
                <th>Total Price</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="listing in listings" :key="listing.id" class="align-middle" :class="{'bg-white text-white': listing.hasOwnProperty('is_self')}" :style="listing.is_self ? '--bs-table-bg: rgba(0,0,0,0.8); background: linear-gradient(5deg, transparent 26%, rgba(255, 2, 251, 0.5)); --bs-table-hover-bg: rgba(255,255,255,0.2);' : ''">
                <td>
                  <Item :item="listing" :listing="true" :hide-quantity="true"/>
                </td>
                <td>{{ listing.Item.name }} <span v-if="canRefine(listing)">+{{ refineLevel(listing) }}</span></td>

                <td>
                  {{ formatPrice(listing.amount) }}
                </td>
                <td>{{ formatPrice(listing.price) }}</td>
                <td>{{ formatPrice(listing.price * listing.amount) }}</td>
                <th>
                  <button @click="openBuyWindow(listing)" class="btn btn-primary" v-if="!listing.is_self">Buy Listing</button>
                </th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="dialogue-bg"></div>
      </div>
    </div>
    <div class="modal fade show d-block bg-black bg-opacity-75" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="listing" @click="listing = null">
      <div class="modal-dialog" @click.stop>
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel"><Item :item="listing" hide-quantity /> {{ listing.Item.name }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="listing = null"></button>
          </div>
          <div class="modal-body">
            <div>Buying <strong>{{ amount }}</strong> {{ listing.Item.name }}</div>
            <div>Cost: {{ amount * listing.price }}</div>
            <input type="range" class="form-range" min="1" :max="listing.amount" v-model="amount" v-if="listing.amount > 1"/>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="buy">Purchase</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Item from "@/components/items/Item";

export default {
  name: 'MarketplaceListings',
  components: {Item},
  data() {
    return {
      listing: null,
      amount: 1,
    }
  },
  mounted() {
    this.$socket.emit('marketplace:get listings', {category: this.$route.params.category})
  },
  computed: {
    ...mapGetters({
      listings: "marketplace/listings"
    }),
    categoryItemName() {
      if (this.listings.length === 0) {
        return "Nothing"
      }
      return this.listings[0].Item.name || "Nothing"
    }
  },
  methods: {
    refineLevel(listing) {
      const attr = listing.item_props;

      if(attr) {
        const level = listing.item_props?.ItemAttributes?.find(s => s.attribute === 'refineLevel');
        return level?.value ?? false
      }

      return false;
    },
    formatPrice(number) {
      return new Intl.NumberFormat('en-gb').format(number);
    },
    openBuyWindow(listing) {
      this.listing = listing;
      this.amount = 1;
    },
    buy() {
      this.$socket.emit('marketplace:buy listing', {
        id: this.listing.id,
        amount: this.amount
      });
      this.listing = null;
      this.amount = 1;
    },
    cancel(listing) {
      this.$socket.emit('marketplace:cancel listing', {
        id: listing.id
      })
    },
    canRefine(listing) {
      return this.refineLevel(listing)
    }
  }
}
</script>